/*
 * @Descripttion: 
 * @version: 
 * @Author: congsir
 * @Date: 2023-03-08 14:21:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-30 14:28:15
 */
import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import simple_view from '../views/simpleView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/simple_view',
    name: 'simple_view',
    component: simple_view
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
