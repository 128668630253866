<template>
    <div class="overflow-hidden w-full h-full relative">


        <!-- <div class="md:hidden">
          <slide-bar class="fixed top-0 bottom-0 z-50 flex h-full w-[260px] flex-none flex-col space-y-2 bg-[#202123] p-2 transition-all sm:absolute sm:top-0"  :currentSessionId='currentSessionId' @toggleClick="toggleSideBar" @newSession="newSession"
            @removeCurrent="removeCurrent" :msgList="msgList"></slide-bar>
        </div> -->


        <!--设置translate -100%-->

        <div id="slide-bar-box"
             class="block slide-transition fixed z-50 top-0 left-[-100%] bottom-0 w-[70%] bg-gray-900 md:fixed md:inset-y-0 md:flex md:w-[260px] md:flex-col md:left-0">
            <slide-bar :currentSessionId='currentSessionId' @toggleClick="toggleSideBar" @newSession="newSession"
                       @removeCurrent="removeCurrent" :msgList="msgList"></slide-bar>
        </div>

        <button type="button" @click="closeSlideBar()" :style="{display:slideBarStatus?'block':'none'}"
                class="fixed hidden left-[70%] bg-gray-900 top-0 z-99  flex h-10 w-10 items-center justify-center focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                tabindex="0"><span class="sr-only">Close sidebar</span>
            <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" stroke-linecap="round"
                 stroke-linejoin="round" class="h-6 w-6 text-white" height="1em" width="1em"
                 xmlns="http://www.w3.org/2000/svg">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
        </button>


        <div class="flex h-full flex-1 flex-col md:pl-[260px]">
            <div
                class="sticky top-0 z-10 flex items-center border-b border-white/20 bg-gray-800 pl-1 pt-1 text-gray-200 sm:pl-3 md:hidden">
                <div @click="openSlideBar()">
                    <button type="button"
                            class="-ml-0.5 -mt-0.5 inline-flex h-10 w-10 items-center justify-center rounded-md hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white dark:hover:text-white"><span
                        class="sr-only">Open sidebar</span>
                        <svg stroke="currentColor" fill="none" stroke-width="1.5"
                             viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="h-6 w-6"
                             height="1em" width="1em"
                             xmlns="http://www.w3.org/2000/svg">
                            <line x1="3" y1="12" x2="21" y2="12"></line>
                            <line x1="3" y1="6" x2="21" y2="6"></line>
                            <line x1="3" y1="18" x2="21" y2="18"></line>
                        </svg>
                    </button>
                </div>

                <h1 class="flex-1 text-center text-base font-normal">New chat</h1>
                <button type="button" class="px-3">
                    <svg
                        stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" stroke-linecap="round"
                        stroke-linejoin="round" class="h-6 w-6" height="1em" width="1em"
                        xmlns="http://www.w3.org/2000/svg">
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                </button>
            </div>
            <main class="relative h-full w-full transition-width flex flex-col overflow-hidden items-stretch flex-1">
                <div class="flex-1 overflow-hidden relative">
                    <div class="h-full dark:bg-gray-800 relative">
                        <main-content :remainDay="remainDay" :conversation="conversation"
                                      :fetchStatus="fetchStatus"></main-content>
                    </div>
                </div>
                <div
                    class="absolute bottom-0 left-0 right-10 w-full dark:border-transparent bg-vert-light-gradient dark:bg-vert-dark-gradient input-area">
                    <send-form :remainDay="remainDay" @resetResponse="resetResponse" @stopGeneration="pauseSearch"
                               :conversation="conversation" ref="sendForm" @handleGetData="handleGetData"
                               :fetchStatus="fetchStatus"></send-form>
                    <div
                        class="px-3 pt-2 pb-3 text-center text-xs text-black/50 dark:text-white/50 md:px-4 md:pt-3 md:pb-6">
                        本站点仅供学习使用，使用前请知晓 <a href="./disclaimer.html" target="_blank" rel="noreferrer"
                                                           style="text-decoration-line:underline">免责申明</a></div>
                </div>
            </main>


            <!-- <ul class="content wid-1100px ">
              <li v-for="(item, index) in dataList" :key="index">
                <div class="user">{{ item.role }}</div>
                <Markdown :source="item.content" />
              </li>
            </ul>

            <div class="search-input-wrapper">
              <div class="search-input-content">
                <input type="text" v-model="searchText" class="pl-2 pr-2" @keyup.enter="handleGetData">
                <button @click="handleGetData" class="search-btn">
                  <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                    stroke-linejoin="round" class="h-4 w-4 mr-1" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <line x1="22" y1="2" x2="11" y2="13"></line>
                    <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                  </svg>
                </button>
              </div>
            </div> -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import {fetchEventSource} from '@microsoft/fetch-event-source';

import {ref, reactive, unref, toRefs, toRaw} from 'vue';
import slideBar from '@/components/slidebar.vue';
import MainContent from '@/components/mainContent.vue';
import SendForm from '@/components/sendForm.vue';
import {openaiUrl, key, defaultToken,freeOpenaiUrl} from '@/config/config';
import slidebar from "@/components/slidebar.vue";

const uuid = require('uuid')
export default {
    name: 'HomeView',
    computed: {
        slidebar() {
            return slidebar
        }
    },
    components: {slideBar, MainContent, SendForm},
    setup(props, context) {
        console.info(props);
        console.info(context);
        let localData = localStorage.getItem("dataList");
        let msgList = reactive(localData ? JSON.parse(localData) : []);
        let currentSession = null;
        let conversation = ref([]);
        let fetchStatus = ref(false);
        let hasNewSession = ref(false);
        let currentSessionId = ref('');
        let isClose = ref(false);
        let remainDay = ref(50);


        const sendForm = ref(null)
        console.info(msgList);

        // 设置一天50次的使用



        const computeTime = () => {
            let localReaminDay = localStorage.getItem("remainDay");
            let localRemainDayTime = localStorage.getItem("remainDayTime");
            if (localRemainDayTime) {
                let now = new Date().getTime();
                if (now - Number(localRemainDayTime) > 24 * 60 * 60 * 1000) {
                    remainDay.value = 50;
                    localStorage.setItem("remainDay", 50);
                    localStorage.setItem("remainDayTime", now);
                } else {
                    remainDay.value = localReaminDay;
                }
            }
        }
        computeTime();



        const getParamsByUrl = (name) => {
            try{
                return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[""])[1].replace(/\+/g,'%20'))||null;

            }catch (e) {
                return null;
            }

        }



        /* 点击获取数据 */
        const handleGetData = (searchVal) => {
            let value = searchVal.value
            if (fetchStatus.value) {
                return;
            }

            if(remainDay.value<=0){
                computeTime();
            }

            if (remainDay.value <= 0) {

                alert("您当天的次数已经用完");
                return;
            }


            console.info("createNewSession", currentSession);

            if (!currentSession) {
                createNewSession(value);
            }
            let item = {role: "user", content: value};
            currentSession.conversation.push(item);
            currentSession.title = value;
            currentSessionId.value = currentSession.id;
            getData(item);

        }


        /* 创建会话 */
        const createNewSession = () => {
            let item = {
                conversation: [{"role": "system", content: 'You are a helpful assistant'}],
                title: '新会话',
                id: generateUuid()
            }
            msgList.unshift(item);
            currentSession = reactive(item);
            conversation.value = item.conversation;
        }

        let token  = getParamsByUrl("token")||defaultToken;
        console.info("token", token);
        /* 请求接口 */
        const getData = (preItem) => {


            const ctrl = new AbortController();
            let sendList = [].concat(toRaw(currentSession.conversation));
            // 获取最后一条数据
            let lastItem = sendList[sendList.length - 1];
            if (lastItem.type == 'error') {
                sendList.pop();
            }

            let item = reactive({role: "assistant", content: ''});
            let currentConversationId = currentSession.id;
            hasNewSession.value = false;
            currentSession.conversation.push(item);
            isClose.value = false;
            fetchStatus.value = true;
            sendForm.value.resetSearchVal();
            let chatUrl = freeOpenaiUrl
            const headers = {
                "Content-Type": 'application/json',
                'Tenant-Identifier':'chitchat'
            }

            if(token&&token.length>100){
                headers["Authorization"] = token;
                chatUrl = openaiUrl
            }


            let eventSource = fetchEventSource(chatUrl, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    chatCompletionRequest: {
                        "model": "gpt-3.5-turbo",
                        // "model_engine" : "davinci-codex",
                        "stream": true,
                        "messages": sendList
                    }
                }),

                signal: ctrl.signal,
                openWhenHidden: true,
                onopen() {
                    console.info("onopen");
                },

                onclose() {
                    console.info("onclose")
                    saveConversation(currentConversationId, sendList);
                    setRemainDay()
                    closeFetch(ctrl)
                },

                onmessage(event) {
                    console.info(event.data);
                    // let data = JSON.parse(event.data);
                    // console.info( decodeURIComponent(atob(data.content)) )
                    // console.info(data);

                    // if (event.data == '[DONE]') {
                    if (event.data == '["DONE"]') {
                        let saveItem = sendList.push(item);
                        saveConversation(currentConversationId, sendList);
                        setRemainDay()
                        closeFetch(ctrl)
                        return
                    }

                    if (isClose.value) {

                        let saveItem = sendList.push(item);
                        setRemainDay()
                        closeFetch(ctrl)
                        return
                    }

                    let data = JSON.parse(event.data);
                    if(data.error){
                        item.type = 'error';
                        item.content =decodeURIComponent(atob(data.error));
                        return
                    }
                    // let currentMsg = data.choices[0]['delta']['content'];
                    let currentMsg = decodeURIComponent(atob(data.content));
                    if (currentMsg !== undefined) {
                        item.content = item.content + currentMsg;
                    }


                },
                onerror(err) {
                    console.info("onerror", err);
                    fetchStatus.value = false;
                    console.info("关闭了----------------");
                    ctrl.abort();
                    // item.type = 'error';
                    throw "onerror";
                }
            })
            console.info("eventSource", eventSource);
        }


        const closeFetch = (ctrl) => {
            fetchStatus.value = false;
            saveMsgList();
            // localStorage.setItem("dataList", JSON.stringify(msgList));
            console.info("关闭了----------------");
            ctrl.abort();
        }


        /*存储conversation到本地*/
        const saveConversation = (key, value) => {
            localStorage.setItem(key, JSON.stringify(value));
        }


        const saveMsgList = () => {

            let list = JSON.parse(JSON.stringify(toRaw(msgList)));

            for (let i = 0; i < list.length; i++) {
                let conversation = list[i].conversation;
                if (conversation.length > 0) {
                    localStorage.setItem(list[i].id, JSON.stringify(conversation));
                    list[i].conversation = [];
                }
            }

            localStorage.setItem("dataList", JSON.stringify(list));

        }

        const setRemainDay = () => {
            remainDay.value = remainDay.value - 1;
            localStorage.setItem("remainDay", remainDay.value);
            localStorage.setItem("remainDayTime", new Date().getTime());
        }

        // const getMyServerData=()=>{
        //     let eventSource = new EventSource(openaiUrl);
        //     eventSource.onmessage = function(res){
        //       console.info(res);
        //     }
        // }


        const pauseSearch = () => {
            isClose.value = true;
        }




        const toggleSideBar = (index) => {
            console.info("toggleSideBar", index);
            let item = msgList[index];
            currentSessionId.value = item.id;
            currentSession = reactive(item);
            let currentConversation = localStorage.getItem(item.id);
            if (currentConversation) {
                conversation.value = JSON.parse(currentConversation);
                currentSession.conversation = conversation.value;
            } else {
                conversation.value = item.conversation;
            }

            hasNewSession.value = false;
        }

        const newSession = () => {
            if (hasNewSession.value) {
                return
            }

            createNewSession();
            hasNewSession.value = true;
        }

        const generateUuid = () => {
            return uuid.v4().replace(/-/g, "")
        }

        const removeCurrent = (index) => {
            if (index == -1) {
                removeAll();
                return
            }

            if (msgList[index].id == currentSessionId.value) {
                msgList.splice(index, 1);
                hasNewSession.value = false;
                currentSession = null;
                conversation.value.length = 0;
                localStorage.removeItem(currentSessionId.value);
                saveMsgList();
            }

        }


        const removeAll = () => {
            for (let i = 0; i < msgList.length; i++) {
                localStorage.removeItem(msgList[i].id);
            }

            localStorage.setItem("dataList", JSON.stringify([]));
            msgList.length = 0;
            conversation.value.length = 0;
            currentSession = null;
            hasNewSession.value = false;

        }

        /* 重新发送最近的一条 */
        const resetResponse = () => {
            if (conversation.value.length == 0) {
                return
            }
            if(remainDay.value<=0){
                computeTime();
            }

            if (remainDay.value <= 0) {
                alert("您当天的次数已经用完");
                return;
            }

            let item = conversation.value.pop();
            if (item.role == 'user') {
                currentSession.conversation.push(item);
                getData(item);
                return
            } else {
                resetResponse();
            }
        }

        let slideBarStatus = ref(false);

        const openSlideBar = () => {
            slideBarStatus.value = true;
            document.getElementById('slide-bar-box').style.left = '0px';
        }

        const closeSlideBar = () => {
            slideBarStatus.value = false;
            document.getElementById('slide-bar-box').style.left = '-100%';
        }

        return {
            handleGetData,
            pauseSearch,
            removeCurrent,
            newSession,
            toggleSideBar,
            generateUuid,
            msgList,
            conversation,
            sendForm,
            fetchStatus,
            currentSessionId,
            resetResponse,
            remainDay,
            openSlideBar,
            closeSlideBar,
            slideBarStatus
        }

    },
}
</script>


<style lang="scss">
.btn {
    align-items: center;
    border-color: transparent;
    border-radius: 0.25rem;
    border-width: 1px;
    display: inline-flex;
    font-size: .875rem;
    line-height: 1.25rem;
    padding: 0.5rem 0.75rem;
    pointer-events: auto;
}

.slide-transition {
    transition: all 0.28s;
}

button {
    cursor: pointer;
}

.btn-neutral {
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    border-color: rgba(0, 0, 0, .1);
    border-width: 1px;
    color: rgba(64, 65, 79, var(--tw-text-opacity));
    font-size: .875rem;
    line-height: 1.25rem;
}

.hidden-scroll-bar::-webkit-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: none;
}
</style>